/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import StandardPageWrapper from '@templates/StandardPageWrapper';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
  Col, Container, Row, Card, CardTitle, CardBody, CardSubtitle,
} from 'reactstrap';
import cx from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import withNullChecks from '@templates/HOC/withNullChecks';
import HeadSEO from '@components/Navigation/HeadSEO';
import { getHeadProps } from '@utils/navigationHelpers';
import {
  MapContainer, TileLayer, Marker, Popup,
} from 'react-leaflet';
import './index.scss';

export const Head = ({ data }) => {
  const { collectionsObject } = data;
  const {
    pageTitle, pageDescription, pageKeywords, pageImgUrl, pageAltText,
  } = getHeadProps(collectionsObject);

  return (
    <HeadSEO
      pageTitle={pageTitle}
      pageDescription={pageDescription}
      pageKeywords={pageKeywords}
      pageImgUrl={pageImgUrl}
      pageAltText={pageAltText}
    />
  );
};

export const pageQuery = graphql`
  query ($slug: String!) {
    collectionsObject: contentfulCollectionsObject(slug: { eq: $slug }) {
      ...CollectionsObjectFragment
    }
  }
`;

const CollectionsObjectTemplate = (props) => {
  const { data, schema } = props;
  const { collectionsObject } = data;
  const { contentTypes } = schema;
  const [selected, setSelected] = useState(false);
  const hero = collectionsObject.images !== null ? collectionsObject.images[0].file.url : 'null';
  const [selectedImage, setSelectedImage] = useState(hero);

  // Returns an object mapping content type field IDs to their display names
  // Used for rendering field names in the info table
  function mapDisplayNames(contentType, includeLinks) {
    const targetContentType = contentTypes.find((type) => type.sys.id === contentType);
    const map = {};

    targetContentType.fields.forEach((field) => {
      const {
        id, name, type, validations,
      } = field;
      map[id] = { name };

      if (includeLinks && (type === 'Link' || type === 'Array')) {
        const [validation] = type === 'Link' ? validations : field.items.validations;

        if (validation && validation.linkContentType) {
          const [linkContentType] = validation.linkContentType;
          map[id].linkContentType = linkContentType;
          map[id].linkFields = mapDisplayNames(linkContentType, false);
        }
      }
    });

    return map;
  }

  const fieldNameMap = mapDisplayNames('collectionsObject', true);

  // Retrieves the display name for a given field ID
  // Provide a content type to retrieve the display name for a linked field
  function findDisplayName(fieldId, contentType) {
    const displayName = contentType
      ? fieldNameMap[contentType]?.linkFields?.[fieldId]?.name
      : fieldNameMap[fieldId]?.name;

    if (!displayName) {
      console.warn(`No display name found for field id: ${fieldId}`);
      return fieldId;
    }

    return displayName;
  }

  const displayNameMap = {};
  // Create map of content type ids tied to display names
  // This allows editors to control how field names are displayed from Contentful
  contentTypes.forEach((contentType) => {
    displayNameMap[contentType.sys.id] = {
      name: contentType.name,
      fields: contentType.fields.map((field) => ({
        id: field.id,
        name: field.name,
      })),
    };
  });

  // Keys that should be skipped when rendering info table
  const ignoreList = [
    'description',
    'descriptionSeo',
    'id',
    'images',
    'latLongNW',
    'relatedObj',
    'slug',
    'titleBreadcrumb',
    'titleDisplay',
  ];

  function getType(inputVar) {
    let type = typeof inputVar;
    if (type === 'object' && Array.isArray(inputVar)) type = 'array';
    return type;
  }

  function algoliaSearchURL(facet, value) {
    const indexName = process.env.GATSBY_ALGOLIA_INDEX_COLLECTIONS;
    const url = `/collections/objects/search/?${indexName}[refinementList][${facet}][0]=${value.toString()}`;
    return url;
  }

  const renderDetailsObject = (contentType, obj) => (
    <ul key={uuidv4()}>
      {Object.keys(obj).map((item) => (obj[item] !== null) && (
      <li key={uuidv4()}>
        <strong>
          {`${findDisplayName(item, contentType)}: `}
        </strong>
        {obj[item]}
      </li>
      ))}
    </ul>
  );

  const renderDetailsArray = (key, arr) => arr.map((item) => {
    const type = getType(item);
    if (type === 'string') {
      return (
        <Link key={uuidv4()} to={algoliaSearchURL(key, item)} className="link-color">
          <button
            id="clear"
            type="button"
            className="btn-sort"
          >
            {item.toString()}
          </button>
        </Link>
      );
    } if (type === 'object') {
      return renderDetailsObject(key, item);
    }
    return null;
  });

  // Make selected image high-res hero image
  function imageClick(image) {
    setSelected(true);
    setSelectedImage(image.file.url);
    window.scrollTo({ top: 0 });
  }

  return (
    <StandardPageWrapper
      headProps={getHeadProps(collectionsObject)}
    >
      <Container className={cx('collections-object-container', 'p-0', 'mb-4', 'mt-0')}>

        {/* Hero image in a card. Renders img tag on select for high quality image. */}
        <Row className="no-gutters">
          <Col md={12}>
            {collectionsObject.images && (
              <Card>
                {selected && (
                <img
                  alt={collectionsObject.slug}
                  src={selectedImage}
                />
                ) }
                {!selected && (
                <GatsbyImage
                  alt={collectionsObject.slug}
                  data-cy="gatsby-img"
                  image={getImage(collectionsObject.images[0])}
                />
                )}
              </Card>
            )}
          </Col>
        </Row>

        {/* Image list. Selected image replaces Hero */}
        {collectionsObject.images && collectionsObject.images.length > 1
        && (
        <Row className="no-gutters">
          <Col md={12} className="select-text">Select thumbnail to view higher resolution.</Col>
          {collectionsObject.images.map((image) => (
            <Col xs={4} sm={4} md={4} onClick={() => imageClick(image)} className={selected && image.file.url === selectedImage ? 'selected' : ''}>
              <Card className="card-image">
                <GatsbyImage
                  key={uuidv4()}
                  alt={collectionsObject.slug}
                  data-cy="gatsby-img"
                  image={getImage(image)}
                />
              </Card>
            </Col>
          ))}
        </Row>
        ) }
        {collectionsObject.images && collectionsObject.images.length === 1
        && (
        <Row className="no-gutters">
          <Col md={12} className="select-text" onClick={() => imageClick(collectionsObject.images[0])}>
            {!selected && <span>Click here to view higher resolution.</span>}
          </Col>
        </Row>
        ) }

        {/* Details section */}
        <Row className="no-gutters">
          <Col md={12} xs={12}>
            <Card>
              <CardTitle tag="h1" className="card-title">{collectionsObject.titleDisplay}</CardTitle>
              <CardBody>
                <p className="object-description">
                  {collectionsObject.description?.description}
                </p>
                <h2>Details</h2>
                {Object.keys(collectionsObject).map((key) => {
                  if (collectionsObject[key] !== null && !ignoreList.includes(key)) {
                    const value = collectionsObject[key];
                    const type = getType(value);
                    return (
                      <React.Fragment key={uuidv4()}>
                        <p>
                          <strong>
                            {findDisplayName(key)}
                            {': '}
                          </strong>
                          {(function renderDetails() {
                            switch (type) {
                              case 'array':
                                return renderDetailsArray(key, value);
                              case 'object':
                                return renderDetailsObject(key, value);
                              default:
                                return value;
                            }
                          }())}
                        </p>
                      </React.Fragment>
                    );
                  }
                  return null;
                })}
              </CardBody>
            </Card>
          </Col>
        </Row>

        {/* Related Objects section */}
        { collectionsObject.relatedObj && (
        <Row className="no-gutters">
          <Col md={12}>
            <h1 className="related-header">Related objects</h1>
          </Col>
          { collectionsObject?.relatedObj?.map((item) => (
            <Col key={uuidv4()} md={4}>
              <Link to={`/${item.slug}`} className="link-color">
                <Card className="related-card">
                  {item.images && (
                  <GatsbyImage
                    alt={item.slug}
                    data-cy="gatsby-img"
                    image={getImage(item.images[0])}
                  />
                  )}
                  <CardBody>
                    <CardTitle tag="h3" className="card-title">{item.titleDisplay}</CardTitle>
                    <CardSubtitle className="subtitle">
                      {item.catalogNumber}
                    </CardSubtitle>
                  </CardBody>
                </Card>
              </Link>
            </Col>
          ))}
        </Row>
        )}
        {collectionsObject.latLongNW?.lat && (
          <div>
            <br />
            <h2>Source location</h2>
            <MapContainer
              style={{ height: '380px' }}
              center={[collectionsObject.latLongNW.lat, collectionsObject.latLongNW.lon]}
              zoom={7}
              scrollWheelZoom={false}
            >
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <Marker position={[collectionsObject.latLongNW.lat, collectionsObject.latLongNW.lon]}>
                <Popup>
                  <strong>{collectionsObject.titleDisplay}</strong>
                  {' '}
                  <br />
                  Lat:
                  {' '}
                  {collectionsObject.latLongNW.lat}
                  {' '}
                  <br />
                  {'Long: '}
                  {collectionsObject.latLongNW.lon}
                </Popup>
              </Marker>
            </MapContainer>
          </div>
        )}
      </Container>
    </StandardPageWrapper>
  );
};

CollectionsObjectTemplate.propTypes = {
  // TODO: Fix this eslint warning and remove whitelisting
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default withNullChecks({ dataKey: 'collectionsObject', typeName: 'collectionsObject' })(
  CollectionsObjectTemplate,
);
